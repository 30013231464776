import React, { useState } from 'react';
import "./Desktop4.css";

//const Desktop2: FunctionComponent = () => {
export default function Desktop2() {

  const [ days, setdays ] = useState(0);
  const [ hours, sethours ] = useState(0);
  const [ minutes, setminutes ] = useState(0);
  const [ seconds, setseconds ] = useState(0);
  const [ faqIndex, setfaqIndex ] = useState("0");

  // async UNSAFE_componentWillMount() {
        setInterval(async() => { 
            const dateFuture = new Date("2023/08/15").getTime();
            const dateNow = new Date().getTime();
            let seconds = Math.floor((dateFuture - (dateNow))/1000);
            let minutes = Math.floor(seconds/60);
            let hours = Math.floor(minutes/60);
            let days = Math.floor(hours/24);

            hours = hours-(days*24);
            minutes = minutes-(days*24*60)-(hours*60);
            seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);    
            setdays(days);
            sethours(hours);
            setminutes(minutes);
            setseconds(seconds);
        },1000)
    //}

   function getfunc(type:number){
      if(type==5){ if(faqIndex=="0"){ setfaqIndex("5") }else { setfaqIndex("0") }  }
      if(type==4){ if(faqIndex=="0"){ setfaqIndex("4") }else { setfaqIndex("0") }  }
      if(type==3){ if(faqIndex=="0"){ setfaqIndex("3") }else { setfaqIndex("0") }  }
      if(type==2){ if(faqIndex=="0"){ setfaqIndex("2") }else { setfaqIndex("0") }  }
      if(type==1){ if(faqIndex=="0"){ setfaqIndex("1") }else { setfaqIndex("0") }  }
   }

  return (
    <div>

    <div className="desktop-2 relative bg-white w-full h-[5790px] overflow-hidden text-left text-lg text-secondary font-heading">
      <img
        className="absolute top-[3764px] left-[1281px] w-[159px] h-[282.83px] overflow-hidden opacity-[0.3]"
        alt=""
        src="/frame.svg"
      />
      <img
        className="absolute top-[1175px] left-[1135px] w-[305px] h-[186px]"
        alt=""
        src="/group-44.svg"
      />
      <img
        className="absolute top-[179px] left-[1226px] w-[55px] h-[55px] overflow-hidden"
        alt=""
        src="/frame1.svg"
      />
      <img
        className="absolute h-[2.36%] w-[100.49%] top-[14.53%] right-[-0.49%] bottom-[83.11%] left-[0%] max-w-full overflow-hidden max-h-full"
        alt=""
        src="/herobg.svg"
      />
      <img
        className="absolute h-[16.98%] w-[72.66%] top-[11.95%] right-[16.93%] bottom-[71.07%] left-[10.42%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/sphere@2x.png"
      />
      <div className="absolute top-[0px] left-[0px] bg-whitesmoke w-[1440px] h-[95px]" />
      <img
        className="absolute h-[6.07%] w-[30.42%] top-[3.95%] right-[58.26%] bottom-[89.98%] left-[11.32%] max-w-full overflow-hidden max-h-full"
        alt=""
        src="/vector.svg"
      />
      <img
        className="absolute top-[3971px] left-[calc(50%_-_720px)] w-[1440px] h-[557px] overflow-hidden"
        alt=""
        src="/frame-20.svg"
      />
      <div className="absolute top-[calc(50%_+_2297px)] left-[calc(50%_-_720px)] w-[1440px] h-[279px] text-sm">
        <div className="absolute top-[calc(50%_-_139.5px)] left-[calc(50%_-_720px)] rounded-xl bg-gray-100 w-[1440px] h-[279px] overflow-hidden">
          <div className="absolute top-[64px] left-[calc(50%_-_176px)] w-[350px] h-[152px]">
            <div className="absolute top-[45px] left-[calc(50%_-_175px)] tracking-[0.01em] font-semibold text-center inline-block w-[350px] opacity-[0.6]">{`Lorem ipsum dolor sit amet consectetur. Sit et consequat non elementum risus nisi. `}</div>
            <div className="absolute top-[0px] left-[64px] text-5xl tracking-[0.01em] leading-[145.05%] font-semibold">
              Still have questions?
            </div>
            <div className="absolute top-[108px] left-[103px] rounded-181xl [background:linear-gradient(-68.55deg,_#280910,_rgba(240,_62,_88,_0.96))] shadow-[2px_2px_11px_rgba(211,_54,_77,_0.38)] box-border w-46 overflow-hidden flex flex-row py-[11px] px-[45px] items-center justify-center text-base text-white font-body-medium border-[0.5px] border-solid border-white">
              <div className="relative tracking-[0.01em] font-semibold">
                Get In Touch
              </div>
            </div>
          </div>
          <div className="absolute top-[109px] left-[5.7px] rounded-11xl bg-gainsboro-200 w-[184px] h-[184px] [transform:_rotate(52.87deg)] [transform-origin:0_0]" />
          <div className="absolute top-[-60px] left-[1467.7px] rounded-11xl bg-gainsboro-200 w-[184px] h-[184px] [transform:_rotate(52.87deg)] [transform-origin:0_0]" />
        </div>
      </div>
      <img
        className="absolute h-[2.34%] w-[100.49%] top-[63.92%] right-[-0.28%] bottom-[33.74%] left-[-0.21%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/herobg1.svg"
      />
      <img
        className="absolute top-[178px] left-[421px] w-0 h-0"
        alt=""
        src="/group-6.svg"
      />
      <img
        className="absolute h-[2.61%] w-[10.49%] top-[7.22%] right-[-6.39%] bottom-[90.17%] left-[95.9%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/bitcoinlogo.svg"
      />
      <div className="absolute top-[5378px] left-[calc(50%_+_406.94px)] rounded-[50%] [background:radial-gradient(50%_50%_at_50%_50%,_rgba(255,_255,_255,_0.47),_rgba(255,_0,_37,_0))] [filter:blur(500px)] w-[681.82px] h-[472.81px] [transform:_rotate(15.32deg)] [transform-origin:0_0] hidden" />
      <div className="absolute top-[433px] left-[461.11px] rounded-[50%] [background:radial-gradient(50%_50%_at_50%_50%,_#fff,_rgba(255,_0,_37,_0))] [filter:blur(500px)] w-[806.5px] h-[806.5px] [transform:_rotate(15.32deg)] [transform-origin:0_0] hidden" />
      <div className="absolute top-[2236px] left-[-180.15px] rounded-[50%] [background:radial-gradient(50%_50%_at_50%_50%,_#fff,_rgba(255,_0,_37,_0))] [filter:blur(500px)] w-[377.87px] h-[377.87px] [transform:_rotate(15.32deg)] [transform-origin:0_0] hidden" />
      <div className="absolute top-[4625px] left-[-180.15px] rounded-[50%] [background:radial-gradient(50%_50%_at_50%_50%,_#fff,_rgba(255,_0,_37,_0))] [filter:blur(500px)] w-[377.87px] h-[377.87px] [transform:_rotate(15.32deg)] [transform-origin:0_0] hidden" />
      <img
        className="absolute top-[339px] left-[729px] w-[147.29px] h-[147.29px] object-cover opacity-[0.55]"
        alt=""
        src="/litecoin1@2x.png"
      />
      <div className="absolute top-[25px] left-[1095px] rounded-181xl [background:linear-gradient(-68.55deg,_#280910,_rgba(240,_62,_88,_0.96))] shadow-[2px_2px_11px_rgba(211,_54,_77,_0.38)] box-border w-[250px] overflow-hidden flex flex-row py-[11px] px-[45px] items-center justify-center text-base text-white font-body-medium border-[0.5px] border-solid border-white">
        <div className="relative tracking-[0.01em] font-semibold">
          Connect Wallet
        </div>
      </div>
      <img
        className="absolute top-[8px] left-[160px] w-[79px] h-[79px] object-cover hidden mix-blend-lighten"
        alt=""
        src="/whatsapp-image-20230717-at-416-4@2x.png"
      />
      <img
        className="absolute h-[0.57%] w-[2.92%] top-[3.2%] right-[63.06%] bottom-[96.23%] left-[34.03%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/starimg.svg"
      />
      <img
        className="absolute h-[0.57%] w-[2.92%] top-[8.7%] right-[67.78%] bottom-[90.73%] left-[29.31%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/starimg.svg"
      />
      <img
        className="absolute h-[0.95%] w-[3.96%] top-[2.57%] right-[55%] bottom-[96.48%] left-[41.04%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/starimg1.svg"
      />
      <div className="absolute top-[221px] left-[160px] text-23xl tracking-[0.01em] leading-[143.4%] font-semibold font-main-heading inline-block w-[719px]">
        <p className="m-0">{`The `}</p>
        <p className="m-0">{`Crypto Trading, `}</p>
        <p className="m-0">The Fibre of Traders</p>
      </div>
      <div className="absolute top-[220px] left-[265px] text-[48px] tracking-[0.01em] font-semibold font-main-heading [background:linear-gradient(-68.55deg,_#280910,_rgba(240,_62,_88,_0.96))] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
        Next Gen
      </div>
      <div className="absolute top-[36px] left-[376px] tracking-[0.01em] font-semibold">
        Home
      </div>
      <div className="absolute top-[36px] left-[480px] tracking-[0.01em] font-semibold">
        Whitepaper
      </div>
      <div className="absolute top-[36px] left-[635px] tracking-[0.01em] font-semibold">
        Roadmap
      </div>
      <div className="absolute top-[36px] left-[771px] tracking-[0.01em] font-semibold">
        ICO
      </div>
      <div className="absolute h-[calc(100%_-_5570px)] w-[calc(100%_-_944px)] top-[574px] right-[160px] bottom-[4996px] left-[784px] rounded-11xl bg-gray-500 shadow-[-3px_-2px_9px_-2px_rgba(0,_0,_0,_0.15)] [backdrop-filter:blur(9px)]" />
      <div className="absolute h-[calc(100%_-_5548px)] w-[calc(100%_-_944px)] top-[206px] right-[160px] bottom-[5342px] left-[784px] rounded-11xl bg-gray-600 shadow-[-2px_-2px_9px_rgba(0,_0,_0,_0.15)] [backdrop-filter:blur(9px)]" />
      <div className="absolute h-[calc(100%_-_5716px)] w-[calc(100%_-_944px)] top-[805px] right-[160px] bottom-[4911px] left-[784px] rounded-11xl bg-gray-700 shadow-[-2px_-2px_9px_rgba(0,_0,_0,_0.15)] [backdrop-filter:blur(9px)]" />
      <div className="absolute top-[213px] left-[790px] rounded-6xl bg-white w-[483px] h-24" />
      <div className="absolute h-[calc(100%_-_5724px)] w-[calc(100%_-_1008px)] top-[227px] right-[192px] bottom-[5497px] left-[816px] text-center text-11xl font-work-sans">
        <div className="absolute h-full top-[0px] bottom-[0px] left-[calc(50%_-_188.09px)] w-[70px] flex flex-col py-0 pr-[16.489999771118164px] pl-[16.510000228881836px] box-border items-center justify-start">
          <b className="relative leading-[45px]">{days}</b>
          <div className="relative text-sm leading-[21px] uppercase font-medium font-inter">
            Days
          </div>
        </div>
        <div className="absolute h-full top-[0px] bottom-[0px] left-[calc(50%_-_104.09px)] flex flex-row pt-0 pb-[21px] pr-[0.06000041961669922px] pl-0 box-border items-start justify-start">
          <b className="relative leading-[45px]">:</b>
        </div>
        <div className="absolute h-full top-[0px] bottom-[0px] left-[calc(50%_-_86.03px)] w-[70px]">
          <b className="absolute top-[0px] left-[15.78px] leading-[45px]">{hours}</b>
          <div className="absolute top-[45px] left-[9.23px] text-sm leading-[21px] uppercase font-medium font-inter">
            Hours
          </div>
        </div>
        <div className="absolute h-full top-[0px] bottom-[0px] left-[calc(50%_-_2.03px)] flex flex-row pt-0 pb-[21px] pr-[0.06000041961669922px] pl-0 box-border items-start justify-start">
          <b className="relative leading-[45px]">:</b>
        </div>
        <div className="absolute h-full top-[0px] bottom-[0px] left-[calc(50%_+_16.03px)] w-[70px] flex flex-col py-0 pr-[3.8399999141693115px] pl-[2.1600000858306885px] box-border items-center justify-start">
          <b className="relative leading-[45px]">{minutes}</b>
          <div className="relative text-sm leading-[21px] uppercase font-medium font-inter">
            Minutes
          </div>
        </div>
        <div className="absolute h-full top-[0px] bottom-[0px] left-[calc(50%_+_100.03px)] flex flex-row pt-0 pb-[21px] pr-[0.06000041961669922px] pl-0 box-border items-start justify-start">
          <b className="relative leading-[45px]">:</b>
        </div>
        <div className="absolute h-full top-[0px] bottom-[0px] left-[calc(50%_+_118.09px)] w-[70px]">
          <b className="absolute top-[0px] left-[16.55px] leading-[45px]">{seconds}</b>
          <div className="absolute top-[45px] left-[0.96px] text-sm leading-[21px] uppercase font-medium font-inter">
            SECONDS
          </div>
        </div>
      </div>
      <div className="absolute w-[calc(100%_-_1008px)] top-[321px] right-[192px] left-[816px] rounded-41xl bg-white shadow-[0px_0px_20px_rgba(0,_0,_0,_0.05)] flex flex-row py-[1.5px] pr-[179.97999572753906px] pl-[180.02000427246094px] box-border items-start justify-start text-center text-sm font-inter">
        <div className="relative leading-[21px] font-semibold">Last Stage</div>
      </div>
      <div className="absolute h-[calc(100%_-_5763px)] w-[calc(100%_-_1008px)] top-[357px] right-[192px] bottom-[5406px] left-[816px] flex flex-row py-0 pr-[78.38999938964844px] pl-[78.61000061035156px] box-border items-start justify-start text-center text-white font-inter">
        <b className="relative leading-[27px]">USDT Raised: 00 / $-1,000,000</b>
      </div>
      <div className="absolute h-[calc(100%_-_5766px)] w-[calc(100%_-_1008px)] top-[403px] right-[192px] bottom-[5363px] left-[816px] text-base text-white font-inter">
        <div className="absolute w-[calc(100%_-_338.14px)] top-[calc(50%_-_0.5px)] right-[338.14px] left-[0px] bg-white h-px" />
        <div className="absolute w-[calc(100%_-_219px)] top-[calc(50%_-_12px)] right-[109.14px] left-[109.86px] flex flex-row items-start justify-start">
          <b className="relative leading-[24px]">1 $DLANCE = $0.048 USDT</b>
        </div>
        <div className="absolute w-[calc(100%_-_338.14px)] top-[calc(50%_-_0.5px)] right-[0px] left-[338.14px] bg-white h-px" />
      </div>
      <div className="absolute h-[calc(100%_-_5626px)] w-[calc(100%_-_1008px)] top-[597px] right-[192px] bottom-[5029px] left-[816px] flex flex-col items-start justify-start gap-[16px] text-sm text-white font-inter">
        <div className="w-[432px] flex flex-col items-start justify-start gap-[7px]">
          <div className="flex flex-row items-start justify-start">
            <div className="relative leading-[21px]">
              <span className="font-medium">{`Amount in `}</span>
              <b>ETH</b>
              <span className="font-medium"> You Pay:</span>
            </div>
          </div>
          <div className="w-[432px] flex flex-row items-start justify-start text-base">
            <div className="rounded-xl bg-gray-900 box-border w-[432px] flex flex-row py-[13px] px-[22px] items-start justify-start border-[1px] border-solid border-gray-800">
              <div className="overflow-hidden flex flex-row pt-0 pb-px pr-[378px] pl-0 items-start justify-start">
                <div className="relative">0</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[432px] flex flex-col items-start justify-start gap-[7px]">
          <div className="flex flex-row items-start justify-start">
            <div className="relative leading-[21px]">
              <span className="font-medium">{`Amount in `}</span>
              <b>$DLANCE</b>
              <span className="font-medium"> You Receive:</span>
            </div>
          </div>
          <div className="rounded-xl w-[432px] flex flex-row items-start justify-start">
            <div className="relative rounded-[13px] bg-gray-900 box-border w-[432px] h-[46px] border-[1px] border-solid border-gray-800" />
          </div>
        </div>
      </div>
      <img
        className="absolute top-[701px] left-[182.88px] w-[55.92px] h-[61.76px] object-cover hidden"
        alt=""
        src="/bitcoin1@2x.png"
      />
      <img
        className="absolute top-[69px] left-[0px] w-[159px] h-[158px] object-cover hidden opacity-[0.35]"
        alt=""
        src="/monero2-copy-3@2x.png"
      />
      <div className="absolute top-[820px] left-[800px] rounded-181xl bg-white w-[210px] h-[45px] overflow-hidden flex flex-row py-[11px] px-[45px] box-border items-center justify-center font-body-medium1">
        <div className="relative tracking-[0.01em] font-semibold">
          Connect Wallet
        </div>
      </div>
      <div className="absolute top-[519px] left-[160px] rounded-181xl [background:linear-gradient(-68.55deg,_#280910,_rgba(240,_62,_88,_0.96))] shadow-[2px_2px_11px_rgba(211,_54,_77,_0.38)] box-border w-[250px] h-11 overflow-hidden flex flex-row py-[11px] px-[45px] items-center justify-center text-base text-white font-body-medium border-[0.5px] border-solid border-white">
        <div className="relative tracking-[0.01em] font-semibold">
          Read Whitepaper
        </div>
      </div>
      <div className="absolute top-[820px] left-[1039px] rounded-181xl box-border w-[200px] h-[42px] overflow-hidden flex flex-row py-[11px] px-[45px] items-center justify-center text-base text-white border-[1px] border-solid border-white">
        <div className="relative tracking-[0.01em] font-semibold">
          How to Buy
        </div>
      </div>
      <div className="absolute top-[426px] left-[160px] text-base tracking-[0.01em] font-light text-darkgray inline-block w-[509px]">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
      </div>
      <img
        className="absolute top-[574px] left-[465.2px] w-[83.55px] h-[83.55px] object-cover hidden"
        alt=""
        src="/etherium2-copy-1@2x.png"
      />
      <img
        className="absolute top-[518px] left-[770px] w-[124px] h-[121px] object-cover hidden"
        alt=""
        src="/monero2-copy-2@2x.png"
      />
      <img
        className="absolute top-[186px] left-[1365px] w-7 h-7 hidden"
        alt=""
        src="/starimg2.svg"
      />
      <img
        className="absolute h-[0.59%] w-[2.36%] top-[2.63%] right-[27.71%] bottom-[96.79%] left-[69.93%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/starimg3.svg"
      />
      <img
        className="absolute h-[0.4%] w-[1.74%] top-[2.52%] right-[13.13%] bottom-[97.08%] left-[85.14%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/starimg4.svg"
      />
      <div className="absolute top-[1000px] left-[119px] w-[1139px] h-[53px] hidden">
        <img
          className="absolute top-[0px] left-[184px] w-[238px] h-[53px] object-cover mix-blend-lighten"
          alt=""
          src="/image-2@2x.png"
        />
        <img
          className="absolute top-[0px] left-[727px] w-[238px] h-[53px] object-cover mix-blend-lighten"
          alt=""
          src="/image-2@2x.png"
        />
        <img
          className="absolute top-[0px] left-[0px] w-[108px] h-[53px] object-cover mix-blend-color-dodge"
          alt=""
          src="/image-3@2x.png"
        />
        <img
          className="absolute top-[0px] left-[1031px] w-[108px] h-[53px] object-cover mix-blend-color-dodge"
          alt=""
          src="/image-3@2x.png"
        />
        <img
          className="absolute top-[1px] left-[499px] w-[152px] h-[52px] object-cover mix-blend-hard-light"
          alt=""
          src="/image-4@2x.png"
        />
        <div className="absolute top-[0px] left-[993px] [background:linear-gradient(269.62deg,_#1b1314_10.02%,_rgba(27,_19,_20,_0))] w-[146px] h-[53px]" />
        <div className="absolute top-[0px] left-[196px] [background:linear-gradient(269.62deg,_#1b1314_10.02%,_rgba(27,_19,_20,_0))] w-[196px] h-[53px] [transform:_rotate(180deg)] [transform-origin:0_0]" />
      </div>
      <div className="absolute top-[1022px] left-[9px] [background:linear-gradient(180deg,_#000,_rgba(0,_0,_0,_0))] [backdrop-filter:blur(20px)] w-[1423px] h-[648px] hidden" />
      <div className="absolute top-[1025px] left-[calc(50%_-_712px)] [background:linear-gradient(0.34deg,_#fff_38.96%,_rgba(255,_255,_255,_0)_85.7%)] w-[1423px] h-[648px]" />
      <img
        className="absolute top-[1553px] left-[82.2px] w-[83.55px] h-[83.55px] object-cover hidden"
        alt=""
        src="/etherium2-copy-1@2x.png"
      />
      <div className="absolute top-[947px] left-[160px] w-[1121px] h-[455px] text-base text-white">
        <img
          className="absolute top-[0px] left-[548px] rounded-tl-[17px] rounded-tr-3xs rounded-b-3xs w-[573px] h-[379px] object-cover"
          alt=""
          src="/rm373batch407-1@2x.png"
        />
        <div className="absolute top-[0px] left-[0px] w-[530px] h-[455px]">
          <img
            className="absolute top-[0px] left-[0px] rounded-[27px] w-[530px] h-[455px]"
            alt=""
            src="/rectangle-1484.svg"
          />
          <div className="absolute top-[379px] left-[49px] rounded-181xl [background:linear-gradient(-68.55deg,_#280910,_rgba(240,_62,_88,_0.96))] w-[250px] h-11 overflow-hidden flex flex-row py-[11px] px-[45px] box-border items-center justify-center font-body-medium">
            <div className="relative tracking-[0.01em] font-semibold">
              Learn More
            </div>
          </div>
          <div className="absolute top-[171px] left-[49px] tracking-[0.01em] font-light text-secondary inline-block w-[445px] opacity-[0.6]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip exLorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex
          </div>
          <div className="absolute top-[110px] left-[49px] text-5xl tracking-[0.01em] leading-[145.05%] font-semibold">
            Accelerate the world’s transition
          </div>
          <div className="absolute top-[37px] left-[49px] text-23xl tracking-[0.01em] font-semibold font-main-heading text-secondary">{`About us `}</div>
          <img
            className="absolute h-[7.47%] w-[6.42%] top-[6.59%] right-[44.15%] bottom-[85.93%] left-[49.43%] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/starimg3.svg"
          />
        </div>
      </div>
      <img
        className="absolute h-[1.28%] w-[5.14%] top-[26.32%] right-[6.74%] bottom-[72.4%] left-[88.13%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/starimg5.svg"
      />
      <img
        className="absolute h-[0.67%] w-[2.71%] top-[45.3%] right-[12.85%] bottom-[54.02%] left-[84.44%] max-w-full overflow-hidden max-h-full"
        alt=""
        src="/starimg6.svg"
      />
      <img
        className="absolute h-[0.67%] w-[2.71%] top-[72.88%] right-[73.96%] bottom-[26.44%] left-[23.33%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/starimg7.svg"
      />
      <img
        className="absolute h-[0.47%] w-[1.88%] top-[46.53%] right-[11.94%] bottom-[53.01%] left-[86.18%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/starimg8.svg"
      />
      <img
        className="absolute h-[0.47%] w-[1.88%] top-[74.23%] right-[7.36%] bottom-[25.3%] left-[90.76%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/starimg9.svg"
      />
      <img
        className="absolute h-[1.28%] w-[5.14%] top-[42.5%] right-[90.35%] bottom-[56.22%] left-[4.51%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/starimg5.svg"
      />
      <div className="absolute top-[2004px] left-[calc(50%_-_187px)] text-23xl tracking-[0.01em] font-semibold font-main-heading">
        Why Choose Us
      </div>
      <div className="absolute top-[calc(50%_-_787px)] left-[calc(50%_-_560px)] rounded-6xl bg-gainsboro-100 w-[352px] flex flex-col py-8 px-6 box-border items-center justify-start gap-[32px] text-center text-13xl">
        <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
          <img className="relative w-20 h-20" alt="" src="/img1.svg" />
          <div className="self-stretch relative tracking-[0.01em] font-semibold">
            Trade Desk
          </div>
          <div className="self-stretch relative text-base tracking-[0.01em] font-light">
            <p className="m-0">{`Invest in crypto anytime, anywhere `}</p>
            <p className="m-0">{`with our safe, secure, and easy to `}</p>
            <p className="m-0">use online platform</p>
          </div>
        </div>
        <div className="rounded-3xs overflow-hidden flex flex-row items-center justify-center gap-[6px] text-lg text-primary">
          <div className="relative tracking-[0.01em] font-semibold">
            Get Started
          </div>
          <img className="relative w-6 h-6" alt="" src="/iconarrow.svg" />
        </div>
      </div>
      <div className="absolute top-[calc(50%_-_787px)] left-[calc(50%_-_176px)] rounded-6xl bg-gainsboro-100 w-[352px] flex flex-col py-8 px-6 box-border items-center justify-start gap-[32px] text-center text-13xl">
        <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
          <img className="relative w-20 h-20" alt="" src="/img2.svg" />
          <div className="self-stretch relative tracking-[0.01em] font-semibold">
            Trade Desk
          </div>
          <div className="self-stretch relative text-base tracking-[0.01em] font-light">
            <p className="m-0">{`Invest in crypto anytime, anywhere `}</p>
            <p className="m-0">{`with our safe, secure, and easy to `}</p>
            <p className="m-0">use online platform</p>
          </div>
        </div>
        <div className="rounded-3xs overflow-hidden flex flex-row items-center justify-center gap-[6px] text-lg text-primary">
          <div className="relative tracking-[0.01em] font-semibold">
            Get Started
          </div>
          <img className="relative w-6 h-6" alt="" src="/iconarrow.svg" />
        </div>
      </div>
      <div className="absolute top-[calc(50%_-_787px)] left-[calc(50%_+_208px)] rounded-6xl bg-gainsboro-100 w-[352px] flex flex-col py-8 px-6 box-border items-center justify-start gap-[32px] text-center text-13xl">
        <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
          <img className="relative w-20 h-20" alt="" src="/img1.svg" />
          <div className="self-stretch relative tracking-[0.01em] font-semibold">
            Trade Desk
          </div>
          <div className="self-stretch relative text-base tracking-[0.01em] font-light">
            <p className="m-0">{`Invest in crypto anytime, anywhere `}</p>
            <p className="m-0">{`with our safe, secure, and easy to `}</p>
            <p className="m-0">use online platform</p>
          </div>
        </div>
        <div className="rounded-3xs overflow-hidden flex flex-row items-center justify-center gap-[6px] text-lg text-primary">
          <div className="relative tracking-[0.01em] font-semibold">
            Get Started
          </div>
          <img className="relative w-6 h-6" alt="" src="/iconarrow.svg" />
        </div>
      </div>



      <div className="absolute top-[3316px] left-[calc(50%_-_116px)] text-23xl tracking-[0.01em] font-semibold font-main-heading">
        Road Map
      </div>
      <div className="absolute top-[4584px] left-[calc(50%_-_373px)] w-[736px] h-[519px] text-sm">
        <div className="absolute top-[0px] left-[calc(50%_-_47px)] text-23xl tracking-[0.01em] font-semibold font-main-heading text-black">
          FAQ
        </div>
        <div className="absolute top-[72px] left-[calc(50%_-_319px)] tracking-[0.01em] font-semibold text-black text-center inline-block w-[640px] opacity-[0.6]">{`Lorem ipsum dolor sit amet consectetur. Sit et consequat non elementum risus nisi. Dictumst pharetra massa faucibus gravida. Eu porta vehicula pharetra et aliquam. `}</div>
        <div className={ faqIndex=="1" ? "absolute top-[148px] left-[0px] rounded-2xl box-border w-[736px] h-[100px] overflow-hidden border-[0.6px] border-solid border-gray-900" :"absolute top-[148px] left-[0px] rounded-2xl box-border w-[736px] h-[59px] overflow-hidden border-[0.6px] border-solid border-gray-900"}>
          {faqIndex=="1" ?   
              <img
                className="absolute top-[28px] left-[700px] w-6 overflow-hidden"
                alt=""
                src="/minus.png"
                onClick={()=>getfunc(1)}
              />
            :
              <img
                className="absolute top-[18px] left-[700px] w-6 h-6 overflow-hidden"
                alt=""
                src="/iconsaxlinearadd.svg"
                onClick={()=>getfunc(1)}
              />
          }
          <div onClick={()=>getfunc(1)} className="absolute top-[18px] left-[calc(50%_-_351px)] tracking-[0.01em] font-semibold opacity-[0.6]">
            FAQ 1: Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
            {faqIndex=="1" &&
               <p>
                 Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi. Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
               </p>
             }
          </div>
        </div>
        <div className={ faqIndex=="2" ? "absolute top-[256px] left-[0px] rounded-2xl box-border w-[736px] h-[100px] overflow-hidden border-[0.6px] border-solid border-gray-900":"absolute top-[256px] left-[0px] rounded-2xl box-border w-[736px] h-[59px] overflow-hidden border-[0.6px] border-solid border-gray-900"}>
          {faqIndex=="2" ?   
              <img
                className="absolute top-[28px] left-[700px] w-6 overflow-hidden"
                alt=""
                src="/minus.png"
                onClick={()=>getfunc(2)}
              />
            :
              <img
                className="absolute top-[18px] left-[700px] w-6 h-6 overflow-hidden"
                alt=""
                src="/iconsaxlinearadd.svg"
                onClick={()=>getfunc(2)}
              />
          }
          <div  onClick={()=>getfunc(2)} className="absolute top-[18px] left-[calc(50%_-_351px)] tracking-[0.01em] font-semibold opacity-[0.6]">
            FAQ 2: Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
            {faqIndex=="2" &&
               <p>
                 Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi. Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
               </p>
             }
          </div>
        </div>
        <div className={ faqIndex=="3" ? "absolute top-[370px] left-[0px] rounded-2xl box-border w-[736px] h-[100px] border-[0.6px] border-solid border-gray-900":"absolute top-[370px] left-[0px] rounded-2xl box-border w-[736px] h-[59px] overflow-hidden border-[0.6px] border-solid border-gray-900"}>
          {faqIndex=="3" ?   
              <img
                className="absolute top-[28px] left-[700px] w-6 overflow-hidden"
                alt=""
                src="/minus.png"
                onClick={()=>getfunc(3)}
              />
            :
              <img
                className="absolute top-[18px] left-[700px] w-6 h-6 overflow-hidden"
                alt=""
                src="/iconsaxlinearadd.svg"
                onClick={()=>getfunc(3)}
              />
          }
          <div onClick={()=>getfunc(3)} className="absolute top-[18px] left-[calc(50%_-_351px)] tracking-[0.01em] font-semibold opacity-[0.6]">
            FAQ 3: Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
            {faqIndex=="3" &&
               <p>
                 Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi. Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
               </p>
             }
          </div>
        </div>
        <div className={ faqIndex=="4" ? "absolute top-[482px] left-[0px] rounded-2xl box-border w-[736px] h-[100px] border-[0.6px] border-solid border-gray-900" : "absolute top-[482px] left-[0px] rounded-2xl box-border w-[736px] h-[59px] border-[0.6px] border-solid border-gray-900"}>
          {faqIndex=="4" ?   
              <img
                className="absolute top-[28px] left-[700px] w-6 overflow-hidden"
                alt=""
                src="/minus.png"
                onClick={()=>getfunc(4)}
              />
            :
              <img
                className="absolute top-[18px] left-[700px] w-6 h-6 overflow-hidden"
                alt=""
                src="/iconsaxlinearadd.svg"
                onClick={()=>getfunc(4)}
              />
          }
          <div onClick={()=>getfunc(4)} className="absolute top-[18px] left-[calc(50%_-_351px)] tracking-[0.01em] font-semibold opacity-[0.6]">
            FAQ 4: Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
            {faqIndex=="4" &&
               <p>
                 Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi. Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
               </p>
             }
          </div>
        </div>
        <div  className={ faqIndex=="5" ? "absolute top-[590px] left-[0px] rounded-2xl box-border w-[736px] h-[100px]  border-[0.6px] border-solid border-gray-900":"absolute top-[590px] left-[0px] rounded-2xl box-border w-[736px] h-[59px]  border-[0.6px] border-solid border-gray-900"}>
          {faqIndex=="5" ?   
              <img
                className="absolute top-[28px] left-[700px] w-6 overflow-hidden"
                alt=""
                src="/minus.png"
                onClick={()=>getfunc(5)}
              />
            :
              <img
                className="absolute top-[18px] left-[700px] w-6 h-6 overflow-hidden"
                alt=""
                src="/iconsaxlinearadd.svg"
                onClick={()=>getfunc(5)}
              />
          }
          <div onClick={()=>getfunc(5)} className="absolute top-[18px] left-[calc(50%_-_351px)] tracking-[0.01em] font-semibold opacity-[0.6]">
            FAQ 5: Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
            {faqIndex=="5" &&
               <p>
                 Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi. Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
               </p>
             }
          </div>
        </div>
      </div>
      <div className="absolute top-[6436px] left-[1156px] w-5 h-5 overflow-hidden" />
      <div className="absolute top-[4075px] left-[160px] text-23xl tracking-[0.01em] font-semibold font-main-heading text-black inline-block w-[235px]">
        News From Us
      </div>
      <div className="absolute top-[4075px] left-[446px] w-64 h-[349px]">
        <div className="absolute h-[32.09%] w-full top-[67.91%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-white" />
        <img
          className="absolute h-[65.62%] w-full top-[0%] right-[0%] bottom-[34.38%] left-[0%] rounded-xl max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/rectangle-39223@2x.png"
        />
        <div className="absolute w-[88.19%] top-[71.92%] left-[5.08%] tracking-[0.01em] font-semibold inline-block">
          A new level of control and ownership over.
        </div>
        <div className="absolute w-[93.75%] top-[91.4%] left-[5.08%] text-smi tracking-[0.01em] inline-block">
          Source : https://www.google.com/s...
        </div>
        <div className="absolute h-[0.09%] w-[97.77%] top-[89.07%] right-[1.11%] bottom-[10.85%] left-[1.11%] box-border border-t-[0.3px] border-solid border-secondary" />
      </div>
      <div className="absolute top-[4075px] left-[734px] w-64 h-[349px]">
        <div className="absolute h-[32.09%] w-full top-[67.91%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-white" />
        <img
          className="absolute h-[65.62%] w-full top-[0%] right-[0%] bottom-[34.38%] left-[0%] rounded-xl max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/rectangle-392231@2x.png"
        />
        <div className="absolute w-[88.19%] top-[71.92%] left-[5.08%] tracking-[0.01em] font-semibold inline-block">
          A new level of control and ownership over.
        </div>
        <div className="absolute w-[93.75%] top-[91.4%] left-[5.08%] text-smi tracking-[0.01em] inline-block">
          Source : https://www.google.com/s...
        </div>
        <div className="absolute h-[0.09%] w-[97.77%] top-[89.07%] right-[1.11%] bottom-[10.85%] left-[1.11%] box-border border-t-[0.3px] border-solid border-secondary" />
      </div>
      <div className="absolute top-[4075px] left-[1022px] w-64 h-[349px]">
        <div className="absolute h-[32.09%] w-full top-[67.91%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-white" />
        <img
          className="absolute h-[65.62%] w-full top-[0%] right-[0%] bottom-[34.38%] left-[0%] rounded-xl max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/rectangle-392232@2x.png"
        />
        <div className="absolute w-[88.19%] top-[71.92%] left-[5.08%] tracking-[0.01em] font-semibold inline-block">
          A new level of control and ownership over.
        </div>
        <div className="absolute w-[93.75%] top-[91.4%] left-[5.08%] text-smi tracking-[0.01em] inline-block">
          Source : https://www.google.com/s...
        </div>
        <div className="absolute h-[0.09%] w-[97.77%] top-[89.07%] right-[1.11%] bottom-[10.85%] left-[1.11%] box-border border-t-[0.3px] border-solid border-secondary" />
      </div>
      <img
        className="absolute h-[0.61%] w-[11.11%] top-[0.5%] right-[77.78%] bottom-[98.89%] left-[11.11%] max-w-full overflow-hidden max-h-full"
        alt=""
        src="/logo.svg"
      />
      <img
        className="absolute h-[0.28%] w-[0.63%] top-[77.6%] right-[11.39%] bottom-[22.12%] left-[87.99%] max-w-full overflow-hidden max-h-full"
        alt=""
        src="/vector1.svg"
      />
      <img
        className="absolute h-[0.28%] w-[0.63%] top-[77.6%] right-[13.68%] bottom-[22.12%] left-[85.69%] max-w-full overflow-hidden max-h-full"
        alt=""
        src="/vector2.svg"
      />
      <div className="absolute top-[0px] left-[calc(50%_-_720px)] w-0 h-0 text-center text-white">
        <div className="absolute top-[5563px] rounded-[50%] [background:radial-gradient(50%_50%_at_50%_50%,_#fff,_rgba(255,_0,_37,_0))] [filter:blur(500px)] w-[681.82px] h-[472.81px] [transform:_rotate(15.32deg)] [transform-origin:0_0] hidden" />
        <div className="absolute top-[5178px] text-23xl tracking-[0.01em] font-semibold font-main-heading text-left hidden">
          Team
        </div>
        <div className="absolute top-[5294px] left-[160px] w-[253px] h-[229px] hidden">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-11xl bg-crimson-100 [backdrop-filter:blur(200px)]" />
          <div className="absolute top-[69.43%] left-[31.62%] tracking-[0.01em] font-semibold">
            Tommy Leo
          </div>
          <div className="absolute top-[81.22%] left-[37.15%] text-sm tracking-[0.01em] font-semibold text-primary">
            Developer
          </div>
          <img
            className="absolute h-[60.26%] w-full top-[0%] right-[0%] bottom-[39.74%] left-[0%] rounded-11xl max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/rectangle-39224@2x.png"
          />
        </div>
        <div className="absolute top-[5294px] left-[449px] w-[253px] h-[229px] hidden">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-11xl bg-crimson-100 [backdrop-filter:blur(200px)]" />
          <div className="absolute top-[69.43%] left-[31.62%] tracking-[0.01em] font-semibold">
            Tommy Leo
          </div>
          <div className="absolute top-[81.22%] left-[37.15%] text-sm tracking-[0.01em] font-semibold text-primary">
            Developer
          </div>
          <img
            className="absolute h-[60.26%] w-full top-[0%] right-[0%] bottom-[39.74%] left-[0%] rounded-11xl max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/rectangle-392241@2x.png"
          />
        </div>
        <div className="absolute top-[5294px] left-[738px] w-[253px] h-[229px] hidden">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-11xl bg-crimson-100 [backdrop-filter:blur(200px)]" />
          <div className="absolute top-[69.43%] left-[31.62%] tracking-[0.01em] font-semibold">
            Tommy Leo
          </div>
          <div className="absolute top-[81.22%] left-[37.15%] text-sm tracking-[0.01em] font-semibold text-primary">
            Developer
          </div>
          <img
            className="absolute h-[60.26%] w-full top-[0%] right-[0%] bottom-[39.74%] left-[0%] rounded-11xl max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/rectangle-392242@2x.png"
          />
        </div>
        <div className="absolute top-[5294px] left-[1027px] w-[253px] h-[229px] hidden">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-11xl bg-crimson-100 [backdrop-filter:blur(200px)]" />
          <div className="absolute top-[69.43%] left-[31.62%] tracking-[0.01em] font-semibold">
            Tommy Leo
          </div>
          <div className="absolute top-[81.22%] left-[37.15%] text-sm tracking-[0.01em] font-semibold text-primary">
            Developer
          </div>
          <img
            className="absolute h-[60.26%] w-full top-[0%] right-[0%] bottom-[39.74%] left-[0%] rounded-11xl max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/rectangle-392243@2x.png"
          />
        </div>
        <div className="absolute top-[5547px] left-[160px] w-[253px] h-[229px] hidden">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-11xl bg-crimson-100 [backdrop-filter:blur(200px)]" />
          <div className="absolute top-[69.43%] left-[31.62%] tracking-[0.01em] font-semibold">
            Tommy Leo
          </div>
          <div className="absolute top-[81.22%] left-[37.15%] text-sm tracking-[0.01em] font-semibold text-primary">
            Developer
          </div>
          <img
            className="absolute h-[60.26%] w-full top-[0%] right-[0%] bottom-[39.74%] left-[0%] rounded-11xl max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/rectangle-392244@2x.png"
          />
        </div>
        <div className="absolute top-[5547px] left-[449px] w-[253px] h-[229px] hidden">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-11xl bg-crimson-100 [backdrop-filter:blur(200px)]" />
          <div className="absolute top-[69.43%] left-[31.62%] tracking-[0.01em] font-semibold">
            Tommy Leo
          </div>
          <div className="absolute top-[81.22%] left-[37.15%] text-sm tracking-[0.01em] font-semibold text-primary">
            Developer
          </div>
          <img
            className="absolute h-[60.26%] w-full top-[0%] right-[0%] bottom-[39.74%] left-[0%] rounded-11xl max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/rectangle-392245@2x.png"
          />
        </div>
        <div className="absolute top-[5547px] left-[738px] w-[253px] h-[229px] hidden">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-11xl bg-crimson-100 [backdrop-filter:blur(200px)]" />
          <div className="absolute top-[69.43%] left-[31.62%] tracking-[0.01em] font-semibold">
            Tommy Leo
          </div>
          <div className="absolute top-[81.22%] left-[37.15%] text-sm tracking-[0.01em] font-semibold text-primary">
            Developer
          </div>
          <img
            className="absolute h-[60.26%] w-full top-[0%] right-[0%] bottom-[39.74%] left-[0%] rounded-11xl max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/rectangle-392246@2x.png"
          />
        </div>
        <div className="absolute top-[5547px] left-[1027px] w-[253px] h-[229px] hidden">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-11xl bg-crimson-100 [backdrop-filter:blur(200px)]" />
          <div className="absolute top-[69.43%] left-[31.62%] tracking-[0.01em] font-semibold">
            Tommy Leo
          </div>
          <div className="absolute top-[81.22%] left-[37.15%] text-sm tracking-[0.01em] font-semibold text-primary">
            Developer
          </div>
          <img
            className="absolute h-[60.26%] w-full top-[0%] right-[0%] bottom-[39.74%] left-[0%] rounded-11xl max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/rectangle-392247@2x.png"
          />
        </div>
      </div>
      <div className="absolute top-[3436px] left-[160px] flex flex-row items-start justify-start gap-[32px] text-center text-base">
        <div className="relative rounded-6xl bg-white shadow-[2px_2px_10px_rgba(0,_0,_0,_0.2)] box-border w-[352px] h-[392px] border-[1px] border-solid border-white">
          <div className="absolute top-[182px] left-[28px] flex flex-row p-2.5 items-center justify-center gap-[8px]">
            <img
              className="relative w-[11.94px] h-[11px] opacity-[0.6]"
              alt=""
              src="/33-right.svg"
            />
            <div className="relative tracking-[0.01em] font-light opacity-[0.6]">
              Invest in crypto anytime, anywhere .
            </div>
          </div>
          <div className="absolute top-[222px] left-[28px] flex flex-row p-2.5 items-center justify-center gap-[8px]">
            <img
              className="relative w-[11.94px] h-[11px] opacity-[0.6]"
              alt=""
              src="/33-right.svg"
            />
            <div className="relative tracking-[0.01em] font-light opacity-[0.6]">
              Invest in crypto anytime, anywhere .
            </div>
          </div>
          <div className="absolute top-[262px] left-[28px] flex flex-row p-2.5 items-center justify-center gap-[8px]">
            <img
              className="relative w-[11.94px] h-[11px] opacity-[0.6]"
              alt=""
              src="/33-right.svg"
            />
            <div className="relative tracking-[0.01em] font-light opacity-[0.6]">
              Invest in crypto anytime, anywhere .
            </div>
          </div>
          <div className="absolute top-[302px] left-[28px] flex flex-row p-2.5 items-center justify-center gap-[8px]">
            <img
              className="relative w-[11.94px] h-[11px] opacity-[0.6]"
              alt=""
              src="/33-right.svg"
            />
            <div className="relative tracking-[0.01em] font-light opacity-[0.6]">
              Invest in crypto anytime, anywhere .
            </div>
          </div>
          <div className="absolute top-[calc(50%_-_68px)] left-[calc(50%_-_143px)] text-13xl tracking-[0.01em] font-semibold">
            Q1 2023
          </div>
          <img
            className="absolute top-[21px] left-[28px] w-20 h-20"
            alt=""
            src="/img.svg"
          />
        </div>
        <div className="relative rounded-6xl bg-white shadow-[2px_2px_10px_rgba(0,_0,_0,_0.2)] box-border w-[352px] h-[392px] border-[1px] border-solid border-white">
          <div className="absolute top-[182px] left-[28px] flex flex-row p-2.5 items-center justify-center gap-[8px]">
            <img
              className="relative w-[11.94px] h-[11px] opacity-[0.6]"
              alt=""
              src="/33-right.svg"
            />
            <div className="relative tracking-[0.01em] font-light opacity-[0.6]">
              Invest in crypto anytime, anywhere .
            </div>
          </div>
          <div className="absolute top-[222px] left-[28px] flex flex-row p-2.5 items-center justify-center gap-[8px]">
            <img
              className="relative w-[11.94px] h-[11px] opacity-[0.6]"
              alt=""
              src="/33-right.svg"
            />
            <div className="relative tracking-[0.01em] font-light opacity-[0.6]">
              Invest in crypto anytime, anywhere .
            </div>
          </div>
          <div className="absolute top-[262px] left-[28px] flex flex-row p-2.5 items-center justify-center gap-[8px]">
            <img
              className="relative w-[11.94px] h-[11px] opacity-[0.6]"
              alt=""
              src="/33-right.svg"
            />
            <div className="relative tracking-[0.01em] font-light opacity-[0.6]">
              Invest in crypto anytime, anywhere .
            </div>
          </div>
          <div className="absolute top-[302px] left-[28px] flex flex-row p-2.5 items-center justify-center gap-[8px]">
            <img
              className="relative w-[11.94px] h-[11px] opacity-[0.6]"
              alt=""
              src="/33-right.svg"
            />
            <div className="relative tracking-[0.01em] font-light opacity-[0.6]">
              Invest in crypto anytime, anywhere .
            </div>
          </div>
          <div className="absolute top-[calc(50%_-_68px)] left-[calc(50%_-_146px)] text-13xl tracking-[0.01em] font-semibold">
            Q2 2023
          </div>
          <img
            className="absolute top-[21px] left-[28px] w-20 h-20"
            alt=""
            src="/img.svg"
          />
        </div>
        <div className="relative rounded-6xl bg-white shadow-[2px_2px_10px_rgba(0,_0,_0,_0.2)] box-border w-[352px] h-[392px] border-[1px] border-solid border-white">
          <div className="absolute top-[182px] left-[28px] flex flex-row p-2.5 items-center justify-center gap-[8px]">
            <img
              className="relative w-[11.94px] h-[11px] opacity-[0.6]"
              alt=""
              src="/33-right.svg"
            />
            <div className="relative tracking-[0.01em] font-light opacity-[0.6]">
              Invest in crypto anytime, anywhere .
            </div>
          </div>
          <div className="absolute top-[222px] left-[28px] flex flex-row p-2.5 items-center justify-center gap-[8px]">
            <img
              className="relative w-[11.94px] h-[11px] opacity-[0.6]"
              alt=""
              src="/33-right.svg"
            />
            <div className="relative tracking-[0.01em] font-light opacity-[0.6]">
              Invest in crypto anytime, anywhere .
            </div>
          </div>
          <div className="absolute top-[262px] left-[28px] flex flex-row p-2.5 items-center justify-center gap-[8px]">
            <img
              className="relative w-[11.94px] h-[11px] opacity-[0.6]"
              alt=""
              src="/33-right.svg"
            />
            <div className="relative tracking-[0.01em] font-light opacity-[0.6]">
              Invest in crypto anytime, anywhere .
            </div>
          </div>
          <div className="absolute top-[302px] left-[28px] flex flex-row p-2.5 items-center justify-center gap-[8px]">
            <img
              className="relative w-[11.94px] h-[11px] opacity-[0.6]"
              alt=""
              src="/33-right.svg"
            />
            <div className="relative tracking-[0.01em] font-light opacity-[0.6]">
              Invest in crypto anytime, anywhere .
            </div>
          </div>
          <div className="absolute top-[calc(50%_-_68px)] left-[calc(50%_-_146px)] text-13xl tracking-[0.01em] font-semibold">
            Q3 2023
          </div>
          <img
            className="absolute top-[21px] left-[28px] w-20 h-20"
            alt=""
            src="/img.svg"
          />
        </div>
      </div>
      <div className="absolute top-[3876px] left-[calc(50%_-_38px)] flex flex-row items-start justify-start gap-[14px]">
        <div className="relative rounded-[50%] bg-primary w-4 h-4" />
        <div className="relative rounded-[50%] bg-gainsboro-200 w-4 h-4 opacity-[0.3]" />
        <div className="relative rounded-[50%] bg-gainsboro-200 w-4 h-4 opacity-[0.3]" />
      </div>
      <div className="absolute h-[calc(100%_-_5697px)] w-[calc(100%_-_944px)] top-[464px] right-[160px] bottom-[5233px] left-[784px] text-center text-sm text-white font-inter">
        <div className="absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-3xl bg-gray-200 shadow-[-2px_-2px_9px_rgba(0,_0,_0,_0.15)]" />
        <div className="absolute w-[calc(100%_-_384px)] top-[29px] right-[371px] left-[13px] rounded-41xl bg-secondary flex flex-row py-2 pr-[33.33000183105469px] pl-[33.939998626708984px] box-border items-center justify-center gap-[12px]">
          <div className="flex flex-row items-start justify-center max-w-[24px]">
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0"
              alt=""
              src="/ethcirclesvg.svg"
            />
          </div>
          <div className="flex flex-row items-start justify-start">
            <b className="relative leading-[14px]">ETH</b>
          </div>
        </div>
        <div className="absolute w-[calc(100%_-_384px)] top-[31px] right-[133px] left-[251px] rounded-41xl bg-dimgray flex flex-row py-2 pr-[33.540000915527344px] pl-[33.72999954223633px] box-border items-center justify-center gap-[12px]">
          <img
            className="relative w-6 h-6 overflow-hidden shrink-0"
            alt=""
            src="/frame2.svg"
          />
          <div className="flex flex-row items-start justify-start">
            <b className="relative leading-[14px]">BTC</b>
          </div>
        </div>
        <div className="absolute w-[calc(100%_-_363.72px)] top-[24px] right-[31.72px] left-[332px] rounded-41xl bg-crimson-200 hidden flex-row py-2 pr-[27.229995727539062px] pl-[28.049999237060547px] box-border items-center justify-center gap-[12px]">
          <div className="flex flex-row items-start justify-center max-w-[24px]">
            <div className="w-6 overflow-hidden shrink-0 flex flex-row py-[3px] px-0 box-border items-start justify-start">
              <img
                className="relative w-6 h-[18px]"
                alt=""
                src="/vector3.svg"
              />
            </div>
          </div>
          <div className="flex flex-row items-start justify-start">
            <b className="relative leading-[14px]">CARD</b>
          </div>
        </div>
        <div className="absolute top-[29px] left-[calc(50%_+_122px)] rounded-41xl bg-dimgray w-28 flex flex-row py-2 pr-[23.970001220703125px] pl-[18px] box-border items-center justify-center gap-[8px] text-xs">
          <img className="relative w-6 h-6" alt="" src="/group.svg" />
          <div className="flex flex-col items-center justify-start">
            <b className="relative leading-[12px]">DOGE</b>
          </div>
        </div>
        <div className="absolute top-[31px] left-[calc(50%_-_116px)] rounded-41xl bg-dimgray w-28 flex flex-row py-2 pr-[23.970001220703125px] pl-[18px] box-border items-center justify-center gap-[8px] text-xs">
          <div className="flex flex-row items-start justify-center max-w-[24px]">
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0"
              alt=""
              src="/usdtcirclesvg.svg"
            />
          </div>
          <div className="flex flex-col items-center justify-start">
            <b className="relative leading-[12px]">USDT</b>
          </div>
        </div>
      </div>
      <img
        className="absolute h-[5.44%] w-[31.58%] top-[27.06%] right-[36.91%] bottom-[67.5%] left-[31.51%] max-w-full overflow-hidden max-h-full object-cover hidden"
        alt=""
        src="/group-2@2x.png"
      />
      <div className="absolute top-[1531px] left-[calc(50%_-_720px)] bg-whitesmoke w-[1440px] h-[393px] overflow-hidden text-base">
        <img
          className="absolute top-[0px] left-[160px] w-[419px] h-[419px] overflow-hidden mix-blend-darken"
          alt=""
          src="/frame3.svg"
        />
        <img
          className="absolute top-[0px] left-[1306px] w-[200px] h-[200px] overflow-hidden"
          alt=""
          src="/frame4.svg"
        />
        <img
          className="absolute top-[160px] left-[159.99px] w-[148.02px] h-[148.1px]"
          alt=""
          src="/sphere1.svg"
        />
        <img
          className="absolute top-[43px] left-[224px] w-[237px] h-[282px] object-cover"
          alt=""
          src="/icons@2x.png"
        />
        <div className="absolute top-[194px] left-[587px] tracking-[0.01em] font-light inline-block w-[627px] opacity-[0.6]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi utboris
          nisi ut aliquip ex
        </div>
        <div className="absolute top-[calc(50%_-_96.5px)] left-[calc(50%_-_132px)] text-13xl tracking-[0.01em] font-semibold inline-block w-[580px]">
          24/7 access to full service customer support
        </div>
        <div className="absolute top-[281px] left-[587px] rounded-181xl [background:linear-gradient(-68.55deg,_#280910,_rgba(240,_62,_88,_0.96))] w-[250px] h-11 overflow-hidden flex flex-row py-[11px] px-[45px] box-border items-center justify-center text-white font-body-medium">
          <div className="relative tracking-[0.01em] font-semibold">
            Learn More
          </div>
        </div>
      </div>


      <div className="absolute top-[2506px] left-[calc(50%_-_720px)] bg-whitesmoke w-[1440px] h-[693px] overflow-hidden text-base">
        <img
          className="absolute top-[43px] left-[224px] w-[437px] h-[582px] object-cover"
          alt=""
          src="/rectangle39226.png"
        />
        <div className="absolute top-[194px] left-[687px] tracking-[0.01em] font-light inline-block w-[627px] opacity-[0.6]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi utboris nisi ut aliquip

Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi utboris nisi ut aliquip exLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi utboris nisi ut aliquip exLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi utboris nisi ut aliquip ex
        </div>
        <div className="absolute top-[100px] left-[687px] text-13xl tracking-[0.01em] font-semibold inline-block w-[580px]">
          WhitePapaer
        </div>
        <div className="absolute top-[481px] left-[687px] rounded-181xl [background:linear-gradient(-68.55deg,_#280910,_rgba(240,_62,_88,_0.96))] w-[250px] h-11 overflow-hidden flex flex-row py-[11px] px-[45px] box-border items-center justify-center text-white font-body-medium">
          <div className="relative tracking-[0.01em] font-semibold">
            View Whitepaper
          </div>
        </div>
      </div>



      <img
        className="absolute h-[1.92%] w-[7.71%] top-[3.01%] right-[58.96%] bottom-[95.08%] left-[33.33%] max-w-full overflow-hidden max-h-full"
        alt=""
        src="/group-61.svg"
      />
      <img
        className="absolute top-[102px] left-[10px] w-[305px] h-[186px]"
        alt=""
        src="/group-44.svg"
      />
      
      <div className="absolute top-[5471px] left-[calc(50%_-_720px)] bg-whitesmoke w-[1440px] h-[319px] overflow-hidden text-center text-smi">
        <div className="absolute top-[74px] left-[calc(50%_-_563px)] w-[1125px] h-[156px]">
          <div className="absolute top-[58px] left-[calc(50%_-_295.5px)] w-[591px] h-8">
            <div className="absolute top-[0px] left-[calc(50%_-_295.5px)] tracking-[0.01em] inline-block w-[591px] opacity-[0.6]">{`Lorem ipsum dolor sit amet consectetur. Sit et consequat non elementum risus nisi. Dictumst pharetra massa faucibus gravida. Eu porta vehicula pharetra et aliquam. `}</div>
          </div>
          <div className="absolute top-[0px] left-[370px] w-[385px] h-[18px] text-left text-sm">
            <div className="absolute top-[0px] left-[0px] tracking-[0.01em] font-semibold">
              Home
            </div>
            <div className="absolute top-[0px] left-[99px] tracking-[0.01em] font-semibold">
              Whitepaper
            </div>
            <div className="absolute top-[0px] left-[237px] tracking-[0.01em] font-semibold">
              Roadmap
            </div>
            <div className="absolute top-[0px] left-[360px] tracking-[0.01em] font-semibold">
              ICO
            </div>
          </div>
          <div className="absolute top-[140px] left-[calc(50%_-_128.5px)] tracking-[0.01em] text-gray-300 opacity-[0.6]">
            2023© Company name | All Rights Reserved
          </div>
          <div className="absolute top-[119.5px] left-[calc(50%_-_563px)] box-border w-[1126px] h-px opacity-[0.3] border-t-[1px] border-solid border-secondary" />
          <div className="absolute top-[66px] left-[964px] flex flex-row items-start justify-start gap-[8px]">
            <img
              className="relative w-5 h-5 overflow-hidden shrink-0"
              alt=""
              src="/frame5.svg"
            />
            <img
              className="relative w-5 h-5 overflow-hidden shrink-0"
              alt=""
              src="/frame6.svg"
            />
            <img
              className="relative w-5 h-5 overflow-hidden shrink-0"
              alt=""
              src="/frame7.svg"
            />
            <img
              className="relative w-5 h-5 overflow-hidden shrink-0"
              alt=""
              src="/frame8.svg"
            />
            <img
              className="relative w-5 h-5 overflow-hidden shrink-0"
              alt=""
              src="/frame9.svg"
            />
            <img
              className="relative w-5 h-5 overflow-hidden shrink-0"
              alt=""
              src="/frame10.svg"
            />
          </div>
        </div>
        <img
          className="absolute h-[11.09%] w-[11.11%] top-[20.69%] right-[77.78%] bottom-[68.22%] left-[11.11%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/logo1.svg"
        />
      </div>
      <img
        className="absolute top-[4515px] left-[0px] w-[154.57px] h-[281.57px] overflow-hidden opacity-[0.3]"
        alt=""
        src="/frame11.svg"
      />
      <img
        className="absolute top-[4756px] left-[1364px] w-[200px] h-[200px] overflow-hidden"
        alt=""
        src="/frame12.svg"
      />
      <img
        className="absolute top-[3396px] left-[0px] w-[200px] h-[200px] overflow-hidden"
        alt=""
        src="/frame13.svg"
      />
    </div>




    <div className="desktop-4">
      <img className="frame-icon" alt="" src="/mobile/frame.svg" />
      <img className="desktop-4-child" alt="" src="/mobile/group-44.svg" />
      <img className="frame-icon1" alt="" src="/mobile/frame1.svg" />
      <div className="sphere">
        <div className="texture">
          <img className="texture-icon" alt="" src="/mobile/texture@2x.png" />
        </div>
      </div>
      <div className="desktop-4-item" />
      <div className="frame-parent">
        <img className="frame-icon2" alt="" src="/mobile/frame4.svg" />
        <div className="info-block-wrapper">
          <div className="info-block">
            <div className="right-parent">
              <img className="right-icon" alt="" src="/mobile/33-right.svg" />
              <div className="invest-in-crypto">
                Invest in crypto anytime, anywhere .
              </div>
            </div>
            <div className="right-group">
              <img className="right-icon" alt="" src="/mobile/33-right.svg" />
              <div className="invest-in-crypto">
                Invest in crypto anytime, anywhere .
              </div>
            </div>
            <div className="right-container">
              <img className="right-icon" alt="" src="/mobile/33-right.svg" />
              <div className="invest-in-crypto">
                Invest in crypto anytime, anywhere .
              </div>
            </div>
            <div className="frame-div">
              <img className="right-icon" alt="" src="/mobile/33-right.svg" />
              <div className="invest-in-crypto">
                Invest in crypto anytime, anywhere .
              </div>
            </div>
            <div className="q1-2023">Q1 2023</div>
            <img className="img-icon" alt="" src="/mobile/img.svg" />
          </div>
        </div>
        <div className="road-map">Road Map</div>
      </div>
      <div className="desktop-4-inner">
        <div className="group-parent">
          
          <div className="frame-child" />
          <div className="frame-item" />
          <div className="news-from-us">News From Us</div>
        </div>
      </div>
      <img className="hero-bg-icon" alt="" src="/mobile/herobg.svg" />
      <img className="group-icon" alt="" src="/mobile/group-6.svg" />
      <img className="bitcoin-logo-icon" alt="" src="/mobile/bitcoinlogo.svg" />
      <div className="ellipse-div" />
      <div className="desktop-4-child1" />
      <div className="desktop-4-child2" />
      <div className="desktop-4-child3" />
      <img className="litecoin-1-icon" alt="" src="/mobile/litecoin1@2x.png" />
      <div className="buttons">
        <div className="button">Connect Wallet</div>
      </div>
      <img
        className="whatsapp-image-2023-07-17-at-4"
        alt=""
        src="/mobile/whatsapp-image-20230717-at-416-4@2x.png"
      />
      <img className="star-img-icon" alt="" src="/mobile/starimg.svg" />
      <img className="star-img-icon1" alt="" src="/mobile/starimg1.svg" />
      <img className="star-img-icon2" alt="" src="/mobile/starimg2.svg" />
      <div className="the-crypto-trading-container">
        <p className="the">{`The `}</p>
        <p className="the">{`Crypto Trading, `}</p>
        <p className="the">The Fibre of Traders</p>
      </div>
      <div className="next-gen">Next Gen</div>
      
      <img className="divabsolute-icon" alt="" src="/mobile/divabsolute.svg" />
      <div className="divabsolute" />
      <div className="rectangle-div" />
      <div className="divwhite">
        <div className="div">
          <b className="b">{days}</b>
          <div className="days">Days</div>
        </div>
        <div className="divfont-work-sans">
          <b className="b">:</b>
        </div>
        <div className="div1">
          <b className="b2">{hours}</b>
          <div className="hours">Hours</div>
        </div>
        <div className="divfont-work-sans1">
          <b className="b">:</b>
        </div>
        <div className="div2">
          <b className="b">{minutes}</b>
          <div className="days">Minutes</div>
        </div>
        <div className="divfont-work-sans2">
          <b className="b">:</b>
        </div>
        <div className="div3">
          <b className="b6">{seconds}</b>
          <div className="seconds">SEC</div>
        </div>
      </div>
      <div className="ptext-center">
        <b className="usdt-raised-00">USDT Raised: 00 / $-1,000,000</b>
      </div>
      <div className="divflex" />
      <div className="div4" />
      <div className="div5">
        <div className="label">
          <div className="amount-in-dlance-container">
            <span className="amount-in">{`Amount in `}</span>
            <b>$DLANCE</b>
            <span className="amount-in"> You Receive:</span>
          </div>
        </div>
        <div className="divrelative">
          <div className="input" />
        </div>
      </div>
      <div className="div6">
        <div className="label">
          <div className="amount-in-eth">Amount in ETH you Pay:</div>
        </div>
        <div className="divrelative">
          <div className="input" />
        </div>
      </div>
      <img className="bitcoin-1-icon" alt="" src="/mobile/bitcoin1@2x.png" />
      <img className="monero-2-copy-3" alt="" src="/mobile/monero2-copy-3@2x.png" />
      <div className="buttons1">
        <div className="button">Read Whitepaper</div>
      </div>
      <div className="lorem-ipsum-dolor1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
      </div>
      <img
        className="etherium-2-copy-1"
        alt=""
        src="/mobile/etherium2-copy-1@2x.png"
      />
      <img className="monero-2-copy-2" alt="" src="/mobile/monero2-copy-2@2x.png" />
      <img className="star-img-icon3" alt="" src="/mobile/starimg21.svg" />
      <img className="star-img-icon4" alt="" src="/mobile/starimg3.svg" />
      <img className="star-img-icon5" alt="" src="/mobile/starimg4.svg" />
      <div className="image-2-parent">
        <img className="image-2-icon" alt="" src="/mobile/image-2@2x.png" />
        <img className="image-5-icon" alt="" src="/mobile/image-2@2x.png" />
        <img className="image-3-icon" alt="" src="/mobile/image-3@2x.png" />
        <img className="image-6-icon" alt="" src="/mobile/image-3@2x.png" />
        <img className="image-4-icon" alt="" src="/mobile/image-4@2x.png" />
        <div className="group-child" />
        <div className="group-item" />
      </div>
      <div className="desktop-4-child4" />
      <div className="desktop-4-child5" />
      <img
        className="etherium-2-copy-11"
        alt=""
        src="/mobile/etherium2-copy-11@2x.png"
      />
      <div className="rm373batch4-07-1-parent">
        <img
          className="rm373batch4-07-1-icon"
          alt=""
          src="/mobile/rm373batch407-1@2x.png"
        />
        <div className="vector-parent">
          <img className="group-inner" alt="" src="/mobile/rectangle-1484.svg" />
          <div className="buttons2">
            <div className="button">Learn More</div>
          </div>
          <div className="lorem-ipsum-dolor2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmo laboris nisi ut aliquip exLorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex
          </div>
          <div className="accelerate-the-worlds">
            Accelerate the world’s transition
          </div>
          <div className="about-us">{`About us `}</div>
          <img className="star-img-icon6" alt="" src="/mobile/starimg5.svg" />
        </div>
      </div>
      <img className="star-img-icon7" alt="" src="/mobile/starimg6.svg" />
      <img className="star-img-icon8" alt="" src="/mobile/starimg7.svg" />
      <img className="star-img-icon9" alt="" src="/mobile/starimg8.svg" />
      <img className="star-img-icon10" alt="" src="/mobile/starimg9.svg" />
      <img className="star-img-icon11" alt="" src="/mobile/starimg10.svg" />
      <img className="star-img-icon12" alt="" src="/mobile/starimg11.svg" />
      <div className="why-choose-us">Why Choose Us</div>
      <div className="info-block1">
        <div className="text">
          <img className="img-icon1" alt="" src="/mobile/img1.svg" />
          <div className="trade-desk">Trade Desk</div>
          <div className="invest-in-crypto-container">
            <p className="the">{`Invest in crypto anytime, anywhere `}</p>
            <p className="the">{`with our safe, secure, and easy to `}</p>
            <p className="the">use online platform</p>
          </div>
        </div>
        <div className="bttn">
          <div className="button">Get Started</div>
          <img className="iconarrow" alt="" src="/mobile/iconarrow.svg" />
        </div>
      </div>
      <div className="info-block2">
        <div className="text">
          <img className="img-icon1" alt="" src="/mobile/img1.svg" />
          <div className="trade-desk">Trade Desk</div>
          <div className="invest-in-crypto-container">
            <p className="the">{`Invest in crypto anytime, anywhere `}</p>
            <p className="the">{`with our safe, secure, and easy to `}</p>
            <p className="the">use online platform</p>
          </div>
        </div>
        <div className="bttn">
          <div className="button">Get Started</div>
          <img className="iconarrow" alt="" src="/mobile/iconarrow.svg" />
        </div>
      </div>
      <div className="info-block3">
        <div className="text">
          <img className="img-icon1" alt="" src="/mobile/img1.svg" />
          <div className="trade-desk">Trade Desk</div>
          <div className="invest-in-crypto-container">
            <p className="the">{`Invest in crypto anytime, anywhere `}</p>
            <p className="the">{`with our safe, secure, and easy to `}</p>
            <p className="the">use online platform</p>
          </div>
        </div>
        <div className="bttn">
          <div className="button">Get Started</div>
          <img className="iconarrow" alt="" src="/mobile/iconarrow.svg" />
        </div>
      </div>
      <div className="frame" />
      <div className="rectangle-parent">
        <div className="instance-child" />
        <img className="instance-item" alt="" src="/mobile/rectangle-39223@2x.png" />
        <div className="a-new-level">
          A new level of control and ownership over.
        </div>
        <div className="source-httpswwwgoogleco">
          Source : https://www.google.com/s...
        </div>
        <div className="instance-inner" />
      </div>
      <div className="rectangle-group">
        <div className="instance-child" />
        <img className="instance-item" alt="" src="/mobile/rectangle-392231@2x.png" />
        <div className="a-new-level">
          A new level of control and ownership over.
        </div>
        <div className="source-httpswwwgoogleco">
          Source : https://www.google.com/s...
        </div>
        <div className="instance-inner" />
      </div>
      <div className="rectangle-container">
        <div className="instance-child" />
        <img className="instance-item" alt="" src="/mobile/rectangle-392232@2x.png" />
        <div className="a-new-level">
          A new level of control and ownership over.
        </div>
        <div className="source-httpswwwgoogleco">
          Source : https://www.google.com/s...
        </div>
        <div className="instance-inner" />
      </div>
      <img className="logo-icon" alt="" src="/mobile/logo.svg" />
      <img className="vector-icon" alt="" src="/mobile/vector.svg" />
      <img className="vector-icon1" alt="" src="/mobile/vector1.svg" />
      <div className="ellipse-parent">
        <div className="group-child1" />
        <div className="team">Team</div>
        <div className="divabsolute-parent">
          <div className="divabsolute1" />
          <div className="tommy-leo">Tommy Leo</div>
          <div className="developer">Developer</div>
          <img
            className="instance-child5"
            alt=""
            src="/mobile/rectangle-39224@2x.png"
          />
        </div>
        <div className="divabsolute-parent">
          <div className="divabsolute1" />
          <div className="tommy-leo">Tommy Leo</div>
          <div className="developer">Developer</div>
          <img
            className="instance-child5"
            alt=""
            src="/mobile/rectangle-39224@2x.png"
          />
        </div>
        <div className="divabsolute-parent">
          <div className="divabsolute1" />
          <div className="tommy-leo">Tommy Leo</div>
          <div className="developer">Developer</div>
          <img
            className="instance-child5"
            alt=""
            src="/mobile/rectangle-39224@2x.png"
          />
        </div>
        <div className="divabsolute-parent">
          <div className="divabsolute1" />
          <div className="tommy-leo">Tommy Leo</div>
          <div className="developer">Developer</div>
          <img
            className="instance-child5"
            alt=""
            src="/mobile/rectangle-39224@2x.png"
          />
        </div>
        <div className="divabsolute-parent">
          <div className="divabsolute1" />
          <div className="tommy-leo">Tommy Leo</div>
          <div className="developer">Developer</div>
          <img
            className="instance-child5"
            alt=""
            src="/mobile/rectangle-39224@2x.png"
          />
        </div>
        <div className="divabsolute-parent">
          <div className="divabsolute1" />
          <div className="tommy-leo">Tommy Leo</div>
          <div className="developer">Developer</div>
          <img
            className="instance-child5"
            alt=""
            src="/mobile/rectangle-39224@2x.png"
          />
        </div>
        <div className="divabsolute-parent">
          <div className="divabsolute1" />
          <div className="tommy-leo">Tommy Leo</div>
          <div className="developer">Developer</div>
          <img
            className="instance-child5"
            alt=""
            src="/mobile/rectangle-39224@2x.png"
          />
        </div>
        <div className="divabsolute-parent">
          <div className="divabsolute1" />
          <div className="tommy-leo">Tommy Leo</div>
          <div className="developer">Developer</div>
          <img
            className="instance-child5"
            alt=""
            src="/mobile/rectangle-39224@2x.png"
          />
        </div>
      </div>
      <div className="ellipse-group">
        <div className="frame-inner" />
        <div className="frame-child1" />
        <div className="frame-child1" />
      </div>
      <div className="divabsolute-parent5">
        <div className="divabsolute9" />
        <div className="button3">
          <div className="eth-circlesvg">
            <img
              className="eth-circlesvg-icon"
              alt=""
              src="/mobile/ethcirclesvg.svg"
            />
          </div>
          <div className="spanfont-inter">
            <b className="eth">ETH</b>
          </div>
        </div>
        <div className="button4">
          <div className="label">
            <b className="eth">BTC</b>
          </div>
        </div>
        
        <div className="button6">
          <div className="eth-circlesvg">
            <img
              className="eth-circlesvg-icon"
              alt=""
              src="/mobile/usdtcirclesvg.svg"
            />
          </div>
          <div className="spanfont-inter3">
            <b className="usdt">USDT</b>
          </div>
        </div>
      </div>
      <img className="frame-icon3" alt="" src="/mobile/frame2.svg" />
      
      <div className="frame-group">
        <img className="frame-icon4" alt="" src="/mobile/frame3.svg" />
        <img className="frame-icon5" alt="" src="/mobile/frame4.svg" />
        <img className="sphere-icon" alt="" src="/mobile/sphere.svg" />
        <img className="icons" alt="" src="/mobile/icons@2x.png" />
        <div className="access-to-full">
          24/7 access to full service customer support
        </div>
      </div>
      <div className="buttons3">
        <div className="button">Learn More</div>
      </div>
      <div className="lorem-ipsum-dolor3">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi utboris nisi ut
        aliquip ex
      </div>
      <img className="desktop-4-child7" alt="" src="/mobile/group-61.svg" />
      <img className="desktop-4-child8" alt="" src="/mobile/group-43.svg" />
      <img className="frame-icon6" alt="" src="/mobile/frame11.svg" />
      <img className="frame-icon7" alt="" src="/mobile/frame12.svg" />
      <img className="frame-icon8" alt="" src="/mobile/frame13.svg" />




        <div className="absolute top-[5820px] left-[13px] w-[352px] h-[519px] text-sm">
        <div className="absolute top-[0px] left-[calc(50%_-_47px)] text-23xl tracking-[0.01em] font-semibold font-main-heading text-black">
          FAQ
        </div>
        <div style={{zIndex:6001}} className={ faqIndex=="1" ? "absolute top-[70px] left-[0px] z-6001 bg-white rounded-2xl box-border w-[97%] h-[180px] overflow-hidden border-[0.6px] border-solid border-gray-900" :"absolute top-[70px] left-[0px] rounded-2xl box-border w-[97%] h-[90px] overflow-hidden border-[0.6px] border-solid border-gray-900"}>
          {faqIndex=="1" ?   
              <img
                className="absolute top-[28px] left-[300px] w-6 overflow-hidden"
                alt=""
                src="/minus.png"
                onClick={()=>getfunc(1)}
              />
            :
              <img
                className="absolute top-[18px] left-[300px] w-6 h-6 overflow-hidden"
                alt=""
                src="/iconsaxlinearadd.svg"
                onClick={()=>getfunc(1)}
              />
          }
          <div onClick={()=>getfunc(1)} className="absolute top-[18px] left-[10px] w-[80%] tracking-[0.01em] font-semibold opacity-[0.6]">
            FAQ 1: Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
            {faqIndex=="1" &&
               <p>
                 Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi. Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
               </p>
             }
          </div>
        </div>
        <div style={{zIndex:5001}} className={ faqIndex=="2" ? "absolute top-[180px] top-[148px] left-[0px] z-5001 bg-white rounded-2xl box-border w-[97%] h-[190px] overflow-hidden border-[0.6px] border-solid border-gray-900":"absolute top-[180px] left-[0px] rounded-2xl box-border w-[97%] h-[90px] overflow-hidden border-[0.6px] border-solid border-gray-900"}>
          {faqIndex=="2" ?   
              <img
                className="absolute top-[28px] left-[300px] w-6 overflow-hidden"
                alt=""
                src="/minus.png"
                onClick={()=>getfunc(2)}
              />
            :
              <img
                className="absolute top-[18px] left-[300px] w-6 h-6 overflow-hidden"
                alt=""
                src="/iconsaxlinearadd.svg"
                onClick={()=>getfunc(2)}
              />
          }
          <div  onClick={()=>getfunc(2)} className="absolute top-[18px] left-[10px] w-[80%] tracking-[0.01em] font-semibold opacity-[0.6]">
            FAQ 2: Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
            {faqIndex=="2" &&
               <p>
                 Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi. Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
               </p>
             }
          </div>
        </div>
        <div style={{zIndex:4001}} className={ faqIndex=="3" ? "absolute top-[290px] top-[148px] left-[0px] z-4001 bg-white rounded-2xl box-border w-[97%] h-[190px] border-[0.6px] border-solid border-gray-900":"absolute top-[290px] left-[0px] rounded-2xl box-border w-[97%] h-[90px] overflow-hidden border-[0.6px] border-solid border-gray-900"}>
          {faqIndex=="3" ?   
              <img
                className="absolute top-[28px] left-[300px] w-6 overflow-hidden"
                alt=""
                src="/minus.png"
                onClick={()=>getfunc(3)}
              />
            :
              <img
                className="absolute top-[18px] left-[300px] w-6 h-6 overflow-hidden"
                alt=""
                src="/iconsaxlinearadd.svg"
                onClick={()=>getfunc(3)}
              />
          }
          <div onClick={()=>getfunc(3)} className="absolute top-[18px] left-[10px] w-[80%] tracking-[0.01em] font-semibold opacity-[0.6]">
            FAQ 3: Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
            {faqIndex=="3" &&
               <p>
                 Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi. Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
               </p>
             }
          </div>
        </div>
        <div  style={{zIndex:3001}} className={ faqIndex=="4" ? "absolute top-[400px] top-[148px] left-[0px] z-3001 bg-white rounded-2xl box-border w-[97%] h-[190px] border-[0.6px] border-solid border-gray-900" : "absolute top-[400px] left-[0px] rounded-2xl box-border w-[97%] h-[90px] border-[0.6px] border-solid border-gray-900"}>
          {faqIndex=="4" ?   
              <img
                className="absolute top-[28px] left-[300px] w-6 overflow-hidden"
                alt=""
                src="/minus.png"
                onClick={()=>getfunc(4)}
              />
            :
              <img
                className="absolute top-[18px] left-[300px] w-6 h-6 overflow-hidden"
                alt=""
                src="/iconsaxlinearadd.svg"
                onClick={()=>getfunc(4)}
              />
          }
          <div onClick={()=>getfunc(4)} className="absolute top-[18px] left-[10px] w-[80%] tracking-[0.01em] font-semibold opacity-[0.6]">
            FAQ 4: Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
            {faqIndex=="4" &&
               <p>
                 Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi. Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
               </p>
             }
          </div>
        </div>
        <div style={{zIndex:2001}} className={ faqIndex=="5" ? "absolute top-[510px] top-[148px] left-[0px] z-2001 bg-white rounded-2xl box-border w-[97%] h-[190px]  border-[0.6px] border-solid border-gray-900":"absolute top-[510px] left-[0px] rounded-2xl box-border w-[97%] h-[90px]  border-[0.6px] border-solid border-gray-900"}>
          {faqIndex=="5" ?   
              <img
                className="absolute top-[28px] left-[300px] w-6 overflow-hidden"
                alt=""
                src="/minus.png"
                onClick={()=>getfunc(5)}
              />
            :
              <img
                className="absolute top-[18px] left-[300px] w-6 h-6 overflow-hidden"
                alt=""
                src="/iconsaxlinearadd.svg"
                onClick={()=>getfunc(5)}
              />
          }
          <div onClick={()=>getfunc(5)} className="absolute top-[18px] left-[10px] w-[80%] tracking-[0.01em] font-semibold opacity-[0.6]">
            FAQ 5: Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
            {faqIndex=="5" &&
               <p>
                 Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi. Lorem ipsum dolor sit amet consectetur. Sit et consequat non
            elementum risus nisi.
               </p>
             }
          </div>
        </div>

        <div className="absolute top-[630px] left-[calc(50%_-_176px)] w-[350px] h-[152px]">
            <div className="absolute top-[45px] left-[calc(50%_-_175px)] tracking-[0.01em] font-semibold text-center inline-block w-[350px] opacity-[0.6]">Lorem ipsum dolor sit amet consectetur. Sit et consequat non elementum risus nisi. </div>
            <div className="absolute top-[0px] left-[64px] text-5xl tracking-[0.01em] leading-[145.05%] font-semibold">Still have questions?</div>
            <div className="absolute top-[108px] left-[83px] rounded-181xl [background:linear-gradient(-68.55deg,_#280910,_rgba(240,_62,_88,_0.96))] shadow-[2px_2px_11px_rgba(211,_54,_77,_0.38)] box-border w-46 overflow-hidden flex flex-row py-[11px] px-[45px] items-center justify-center text-base text-white font-body-medium border-[0.5px] border-solid border-white">
                <div className="relative tracking-[0.01em] font-semibold">Get In Touch</div>
            </div>


             <div className="absolute top-[180px] left-[-12px] bg-whitesmoke w-[110%] h-[319px] overflow-hidden text-center text-smi">
            <div className="absolute top-[74px] left-[calc(50%_-_563px)] w-[1125px] h-[25px]">
              
            
              <div className="absolute top-[30px] left-[calc(50%_-_128.5px)] tracking-[0.01em] text-gray-300 opacity-[0.6]">2023© Company name | All Rights Reserved</div>
              <div className="absolute top-[119.5px] left-[calc(50%_-_563px)] box-border w-[1126px] h-px opacity-[0.3] border-t-[1px] border-solid border-secondary"></div>
              <div style={{ marginTop:-8}} className="gap-[8px]">
                  <img className="relative w-5 h-5 mr-5 overflow-hidden shrink-0" alt="" src="/frame5.svg" />
                  <img className="relative w-5 h-5 mr-5 overflow-hidden shrink-0" alt="" src="/frame6.svg" />
                  <img className="relative w-5 h-5 mr-5 overflow-hidden shrink-0" alt="" src="/frame7.svg" />
                  <img className="relative w-5 h-5 mr-5 overflow-hidden shrink-0" alt="" src="/frame8.svg" />
                  <img className="relative w-5 h-5 mr-5 overflow-hidden shrink-0" alt="" src="/frame9.svg" />
                  <img className="relative w-5 h-5 mr-5 overflow-hidden shrink-0" alt="" src="/frame10.svg" />
              </div>
          </div>
          <img className="absolute h-[11.09%] w-[70%] top-[5.69%] right-[77.78%] bottom-[68.22%] left-[11.11%] max-w-full overflow-hidden max-h-full" alt="" src="/logo1.svg" />
      </div>

        </div>



          


      </div>




    </div>



    </div>
  );
};

//export default Desktop2;
